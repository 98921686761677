import React, { Component } from 'react';
import { CountdownNumbers, Footnote2 } from '../Typography';
import {
  CountdownWrapper,
  CountdownItemsList,
  CountdownItemWrapper,
  InnerCountdownItemWrapper,
  CountdownSubTextWrapper
} from './styles';

interface CountdownProps {
  date: string;
}

class Countdown extends Component<CountdownProps> {
  public state = {
    days: 0,
    hours: 0,
    min: 0,
    sec: 0
  };

  private interval = 0;

  constructor(props: CountdownProps) {
    super(props);
  }

  private setNewCountdownDate() {
    const date = this.calculateCountdown(this.props.date);
    date ? this.setState(date) : this.stop();
  }

  componentDidMount() {
    // set initial countdown date
    this.setNewCountdownDate();

    // update every second
    this.interval = setInterval(() => {
      this.setNewCountdownDate();
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate: string) {
    const endDateString = new Date(endDate).toUTCString();
    const today = new Date().toUTCString();
    let diff = (Date.parse(endDateString) - Date.parse(today)) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  addLeadingZeros(value: number) {
    let StringifiedValue = String(value);
    while (StringifiedValue.length < 2) {
      StringifiedValue = '0' + StringifiedValue;
    }
    return StringifiedValue;
  }

  render() {
    const countDown = this.state;

    return (
      <CountdownWrapper>
        <CountdownItemsList>
          <CountdownItemWrapper>
            <InnerCountdownItemWrapper>
              <CountdownNumbers>{this.addLeadingZeros(countDown.days)}</CountdownNumbers>
            </InnerCountdownItemWrapper>
            <CountdownSubTextWrapper>
              <Footnote2 style={{ textTransform: 'uppercase' }}>{countDown.days === 1 ? 'day' : 'days'}</Footnote2>
            </CountdownSubTextWrapper>
          </CountdownItemWrapper>

          <CountdownItemWrapper>
            <InnerCountdownItemWrapper>
              <CountdownNumbers>{this.addLeadingZeros(countDown.hours)}</CountdownNumbers>
            </InnerCountdownItemWrapper>
            <CountdownSubTextWrapper>
              <Footnote2 style={{ textTransform: 'uppercase' }}>hours</Footnote2>
            </CountdownSubTextWrapper>
          </CountdownItemWrapper>

          <CountdownItemWrapper>
            <InnerCountdownItemWrapper>
              <CountdownNumbers>{this.addLeadingZeros(countDown.min)}</CountdownNumbers>
            </InnerCountdownItemWrapper>
            <CountdownSubTextWrapper>
              <Footnote2 style={{ textTransform: 'uppercase' }}>min</Footnote2>
            </CountdownSubTextWrapper>
          </CountdownItemWrapper>

          <CountdownItemWrapper>
            <InnerCountdownItemWrapper>
              <CountdownNumbers>{this.addLeadingZeros(countDown.sec)}</CountdownNumbers>
            </InnerCountdownItemWrapper>
            <CountdownSubTextWrapper>
              <Footnote2 style={{ textTransform: 'uppercase' }}>sec</Footnote2>
            </CountdownSubTextWrapper>
          </CountdownItemWrapper>
        </CountdownItemsList>
      </CountdownWrapper>
    );
  }
}

export default Countdown;
