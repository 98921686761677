import styled from 'styled-components';
import theme from '../../theme';

export const LargeTitle = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  font-family: 'Abhaya Libre', sans-serif;
  font-weight: 800;
  font-size: 48px;
  line-height: 57px;
  color: white;

  @media (max-width: ${theme.mediaBreakPoints.mobileMax}px) {
    font-size: 38px;
    line-height: 42px;
  }
`;

export const Title1 = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  font-family: 'Abhaya Libre', sans-serif;
  font-weight: 800;
  font-size: 40px;
  line-height: 47px;
  color: white;
`;

export const SubHeadline = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: white;

  @media (max-width: ${theme.mediaBreakPoints.mobileMax}px) {
    font-size: 16px;
  }
`;

export const BodyText = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: white;
`;

export const Callout = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  color: white;
`;

export const Footnote1 = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: white;
`;

export const Footnote2 = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: white;
`;

export const CountdownNumbers = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 46px;
  line-height: 56px;
  color: white;

  @media (max-width: ${theme.mediaBreakPoints.mobileMax}px) {
    font-size: 36px;
  }
`;

export const FooterText = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: white;
`;
