import styled from 'styled-components';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import theme from '../../theme';

export const ComboTextField = styled(OutlinedInput)<any>`
  &.MuiOutlinedInput-root {
    background: ${theme.colors.BACKGROUND_MAIN};
    border: 1px solid #938e9d;
    box-sizing: border-box;
    border-radius: 4px;
    color: white;

    input::placeholder {
      color: #938e9d;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
  &.MuiOutlinedInput-adornedEnd {
    padding: 0;
    width: 50%;

    @media (max-width: ${theme.mediaBreakPoints.mobileMax}px) {
      width: 80%;
    }
  }
`;

export const SignUpButton = styled(Button)`
  &.MuiButtonBase-root {
    background: white;
    color: ${theme.colors.NEUVYBE_MAIN_BUTTON};
    border: 0;
    z-index: 1;

    &:hover {
      background: white;
      color: ${theme.colors.NEUVYBE_MAIN_BUTTON_HOVER};
      border: 0;
    }
  }
  &.MuiButton-text {
    display: inline-block;
    margin: 0;
    padding: 15px 25px;
    white-space: nowrap;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
  }
`;
