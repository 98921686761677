import React from 'react';
import mixpanel from 'mixpanel-browser';
// import { Link } from "gatsby";
import Link from 'gatsby-link';
import Box from '@material-ui/core/Box';
import { FooterText } from '../Typography';
import { LinkTextButton } from '../Button';
import {
  FooterContainer,
  NeuVybeLogoMark,
  FooterTopContainer,
  FooterBottomContainer,
  FooterLogoCombo,
  SocialMediaItemsContainer
} from './styles';
import { routes } from '../../shared/global';
import NeuVybeLogoMarkImage from '../../media/images/Neuvybe_Logomark_Web_Positive_Colour_Primary.png';

const Footer = (): React.ReactElement => {
  const onFacebookClick = (): void => {
    mixpanel.track('(Facebook Footer Link) Clicked', {
      timestamp: new Date().toISOString()
    });
  };

  const onTwitterClick = (): void => {
    mixpanel.track('(Twitter Footer Link) Clicked', {
      timestamp: new Date().toISOString()
    });
  };

  const onInstagramClick = (): void => {
    mixpanel.track('(Instagram Footer Link) Clicked', {
      timestamp: new Date().toISOString()
    });
  };

  const onPrivacyPolicyClick = (): void => {
    mixpanel.track('(Privacy Policy) Clicked', {
      timestamp: new Date().toISOString()
    });
  };

  const onTermsOfUseClick = (): void => {
    mixpanel.track('(Terms of Use) Clicked', {
      timestamp: new Date().toISOString()
    });
  };

  return (
    <FooterContainer>
      <FooterTopContainer>
        <FooterLogoCombo>
          <NeuVybeLogoMark src={NeuVybeLogoMarkImage} />
          <FooterText>© {new Date().getFullYear()} NeuVybe Inc. All rights reserved.</FooterText>
        </FooterLogoCombo>
        <SocialMediaItemsContainer>
          <a
            onClick={onFacebookClick}
            href="https://facebook.com/neuvybe"
            target="_blank"
            rel="noopener"
            style={{ textDecoration: 'none' }}
          >
            <LinkTextButton>
              <FooterText style={{ textTransform: 'uppercase' }}>Facebook</FooterText>
            </LinkTextButton>
          </a>
          <a
            onClick={onTwitterClick}
            href="https://twitter.com/neuvybe"
            target="_blank"
            rel="noopener"
            style={{ textDecoration: 'none' }}
          >
            <LinkTextButton>
              <FooterText style={{ textTransform: 'uppercase' }}>Twitter</FooterText>
            </LinkTextButton>
          </a>
          <a
            onClick={onInstagramClick}
            href="https://instagram.com/neuvybe"
            target="_blank"
            rel="noopener"
            style={{ textDecoration: 'none' }}
          >
            <LinkTextButton>
              <FooterText style={{ textTransform: 'uppercase' }}>Instagram</FooterText>
            </LinkTextButton>
          </a>
        </SocialMediaItemsContainer>
      </FooterTopContainer>
      <FooterBottomContainer>
        <Box mx="auto">
          <Link
            onClick={onPrivacyPolicyClick}
            to={routes.privacyPolicy}
            title="Privacy Policy"
            style={{ textDecoration: 'none' }}
          >
            <LinkTextButton>
              <FooterText>Privacy Policy</FooterText>
            </LinkTextButton>
          </Link>
          <FooterText style={{ verticalAlign: 'middle', fontSize: 18 }}>&nbsp;&#124;&nbsp;</FooterText>
          <Link
            onClick={onTermsOfUseClick}
            title="Terms of Use"
            to={routes.termsOfUse}
            style={{ textDecoration: 'none' }}
          >
            <LinkTextButton>
              <FooterText>Terms of Use</FooterText>
            </LinkTextButton>
          </Link>
        </Box>
      </FooterBottomContainer>
    </FooterContainer>
  );
};

export default Footer;
