import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '../src/theme';
import Firebase from '../src/classes/firebase';
import { SnackbarProvider } from 'notistack';
import mixpanel from 'mixpanel-browser';

export default class PolarisApp extends React.PureComponent {
  componentDidMount() {
    Firebase.initialize();
    mixpanel.init(process.env.GATSBY_MIXPANEL_TOKEN as string);
  }

  render() {
    const { children } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={1}>{children}</SnackbarProvider>
      </ThemeProvider>
    );
  }
}
