import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import theme from '../../theme';

export const CountdownWrapper = styled.div`
  text-align: center;
  padding: 20px 0;
`;

export const CountdownItemsList = styled.ul`
  float: none;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const CountdownItemWrapper = styled.li`
  margin-left: 15px;
  display: inline-block;
  &:first-child {
    margin-left: 0;
  }
`;

export const InnerCountdownItemWrapper = styled.div`
  border-radius: 8px;
  border-style: solid;
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
  min-width: 70px;
  min-height: 70px;
  border: 1px solid #ffffff;

  @media (max-width: ${theme.mediaBreakPoints.mobileMax}px) {
    padding: 5px;
    min-width: 55px;
    min-height: 55px;
  }
`;

export const CountdownSubTextWrapper = styled(Box)`
  margin-top: 10px;
`;
