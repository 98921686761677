import firebase from 'firebase/app';
import 'firebase/firestore';

export interface ContactFormObject {
  name: string;
  email: string;
  phoneNumber?: string;
  message: string;
}

class Firebase {
  private static firestore: firebase.firestore.Firestore;

  public static initialize() {
    if (!this.firestore) {
      const firebaseConfig = {
        apiKey: 'AIzaSyAxYYuIhrUR9J_O_ckIC2QRgpcBm6sqgxE',
        authDomain: 'neuvybe.firebaseapp.com',
        databaseURL: 'https://neuvybe.firebaseio.com',
        projectId: 'neuvybe',
        storageBucket: 'neuvybe.appspot.com',
        messagingSenderId: '107035811917',
        appId: '1:107035811917:web:c1c34f3110ef46cb953c04'
      };

      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);

      this.firestore = firebase.firestore();
    }
  }

  public static async saveComingSoonEmail(email: string) {
    return await this.firestore.collection('coming_soon_emails').add({ email });
  }

  public static async saveContactFormMessage(contactFormObject: ContactFormObject) {
    return await this.firestore.collection('contact_forms').add(contactFormObject);
  }
}

export default Firebase;
