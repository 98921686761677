export const theme = {
  breakpoints: {
    mobileMin: '0',
    mobileMax: '600px',
    tabletMin: '601px',
    tabletMax: '1024px',
    desktopMin: '1025px',
    desktopMax: '1450px',
    desktopWideMin: '1451px',
    desktopWideMax: '1900px'
  },
  fontUrl: 'https://fonts.googleapis.com/css?family=Abhaya+Libre:800|Montserrat:400,600,700&display=swap',
  fonts: {
    primary1: "'Libre Franklin', sans-serif"
  },
  fontSizes: {
    xxs: '10px',
    xs: '12px',
    sm: '14px',
    med: '16px',
    lg: '20px',
    xl: '24px',
    xxl: '40px',
    xxxl: '60px'
  },
  colors: {
    WHITE: '#ffffff',
    // BACKGROUND_MAIN: '#0E021A',
    BACKGROUND_MAIN: '#060606',
    BACKGROUND_SECONDARY: '#100e0e',
    NEUVYBE_MAIN: '#EC2F5D',
    BLACK: 'black',
    DARK_GREY: '#303030',
    NEUVYBE_MAIN_BUTTON: '#EC2F5D',
    NEUVYBE_MAIN_BUTTON_HOVER: '#C80E3B'
  },
  mediaBreakPoints: {
    mobileMin: 300,
    mobileMax: 600,
    tabletMin: 600,
    tabletMax: 992,
    desktopMin: 992,
    desktopMax: 1440
  }
};

export default theme;
