import styled from 'styled-components';
import MaterialUIButton from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import theme from '../../theme';

export const PrimaryButtonStyled = styled(MaterialUIButton)`
  &.MuiButtonBase-root {
    background: transparent;
    text-transform: capitalize;
    border-radius: 0;
    border: 2px solid ${theme.colors.NEUVYBE_MAIN_BUTTON};
    color: ${theme.colors.NEUVYBE_MAIN_BUTTON};
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;

    &:hover {
      background: transparent;
      border: 2px solid ${theme.colors.NEUVYBE_MAIN_BUTTON_HOVER};
      color: ${theme.colors.NEUVYBE_MAIN_BUTTON_HOVER};
    }
  }

  &.MuiButton-text {
    padding: 10px 25px;
  }
`;

export const Primary2ButtonStyled = styled(MaterialUIButton)`
  &.MuiButtonBase-root {
    background: white;
    text-transform: capitalize;
    border-radius: 0;
    border: 2px solid white;
    color: ${theme.colors.NEUVYBE_MAIN_BUTTON};
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;

    &:hover {
      background: #f2f2f2;
      border: 2px solid #f2f2f2;
      color: ${theme.colors.NEUVYBE_MAIN_BUTTON_HOVER};
    }
  }

  &.MuiButton-text {
    padding: 10px 25px;
  }
`;

export const SecondaryButtonStyled = styled(MaterialUIButton)`
  &.MuiButtonBase-root {
    min-width: auto;
    text-transform: capitalize;
    border-radius: 0;
    border: 0;
    color: white;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;

    &:hover,
    :active {
      color: ${theme.colors.NEUVYBE_MAIN_BUTTON_HOVER};
      padding: 0;
    }
  }

  &.MuiButton-text {
    padding: 0;
  }
`;

export const Image = styled.img`
  width: inherit;
  user-select: none;
  pointer-events: none;
`;

export const ImageAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    width: inherit;
    height: inherit;
    user-select: none;
    pointer-events: none;
  }
`;

export const ImageButton = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 0;
    opacity: ${(props: any) => (props.disabled ? 0.5 : 1)};
  }
`;

export const LinkTextButtonStyled = styled(IconButton)`
  &.MuiIconButton-root {
    border-radius: 0;
    padding: 0;
    opacity: ${(props: any) => (props.disabled ? 0.5 : 1)};

    &:hover,
    :active {
      div {
        color: ${theme.colors.NEUVYBE_MAIN_BUTTON_HOVER};
      }
    }
  }
`;
