import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import BottomHeroImage from '../../media/images/bottomhero_img.png';
import theme from '../../theme';

export const BottomHeroContainer = styled.div`
  width: 100%;
  background: url(${BottomHeroImage});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const BottomHeroContainerOverlay = styled.div`
  width: 100%;
  height: 100%;
  min-height: 776px;
  background-size: cover;
  background-repeat: no-repeat;
  // background: linear-gradient(180deg, #0E021A 7.34%, rgba(14, 2, 26, 0.49) 97.4%);
  // background: linear-gradient(to top, transparent 0%, #0f021a 100%);
  background: ${theme.colors.BACKGROUND_MAIN};
  opacity: 0.9;
`;

export const BottomHeroInfoContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const BottomHeroInfo = styled(Box)`
  position: relative;
  text-align: center;
  margin: 0;
  padding-top: 225px;

  @media (max-width: ${theme.mediaBreakPoints.mobileMax}px) {
    padding-top: 100px;
  }
`;
