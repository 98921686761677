import React, { useState, useEffect, useRef, useCallback, CSSProperties } from 'react';
import mixpanel from 'mixpanel-browser';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Link from 'gatsby-link';
import { useFormik } from 'formik';
import Box from '@material-ui/core/Box';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import theme from '../../theme';
import { LargeTitle } from '../../components/Typography';
import { routes } from '../../shared/global';
import {
  NavHeaderContainer,
  NavHeaderItemsContainer,
  NeuVybeLogoType,
  NeuVybeLogoMark,
  NeuVybeLogoTypeWrapper,
  ContactFormContainer,
  ContactFormCloseButton,
  ContactFormHeaderWrapper,
  ContactFormTextField
} from './styles';
import { PrimaryButton } from '../Button';
import NeuVybeLogoTypeImage from '../../media/images/Neuvybe_Logotype_Web_Horizontal_Positive_Colour_Primary.png';
import NeuVybeLogoMarkImage from '../../media/images/Neuvybe_Logomark_Web_Positive_Colour_Primary.png';
import { validateEmail, validatePhoneNumber } from '../../shared/utils';
import Firebase from '../../classes/firebase';

interface NavHeaderProps extends WithSnackbarProps {
  sticky?: boolean;
  navHeaderOffset?: number;
  containerStyle?: CSSProperties;
}

const NavHeader = ({
  sticky,
  navHeaderOffset,
  containerStyle,
  enqueueSnackbar
}: NavHeaderProps): React.ReactElement => {
  const NAV_HEADER_OFFSET = navHeaderOffset || 0;
  const [openDialog, setDialogOpenState] = React.useState(false);
  const [isSticky, setSticky] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(`(max-width:${theme.mediaBreakPoints.mobileMax}px)`);

  const handleNeuVybeLogoClick = (): void => {
    mixpanel.track('NeuVybe Logo Home Button Clicked', {
      timestamp: new Date().toISOString()
    });
  };

  const handleContactButtonClick = (): void => {
    setDialogOpenState(true);
    mixpanel.track('Contact Form Button Clicked', {
      timestamp: new Date().toISOString()
    });
  };

  const handleContactFormClose = (): void => {
    setDialogOpenState(false);
  };

  const formik: any = useFormik({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      message: ''
    },
    onSubmit: async values => {
      let errorMessage = '';
      if (!values.name) {
        errorMessage = 'Name is required';
      } else if (!values.email) {
        errorMessage = 'Email address is required';
      } else if (!validateEmail(values.email)) {
        errorMessage = 'Invalid email address';
      } else if (values.phoneNumber && !validatePhoneNumber(values.phoneNumber)) {
        errorMessage = 'Invalid phone number';
      } else if (!values.message) {
        errorMessage = 'Message is required';
      }

      if (errorMessage) {
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        });
      } else {
        try {
          await Firebase.saveContactFormMessage(values);
          const successMessage = "Thanks for your inquiry. We'll get back to your shortly";
          enqueueSnackbar(successMessage, {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          });
          handleContactFormClose();

          mixpanel.track('Contact Form Sumbitted', {
            timestamp: new Date().toISOString()
          });
        } catch (err) {
          errorMessage = err.message;
          enqueueSnackbar(errorMessage, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          });
        }
      }
    }
  });

  const handleScroll = useCallback(() => {
    if (headerRef && headerRef.current) {
      const newIsStickyValue = (window.pageYOffset || window.scrollY) > NAV_HEADER_OFFSET || isMobile;
      setSticky(newIsStickyValue);
    }
  }, [isMobile]);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return (): void => {
      window.removeEventListener('scroll', () => handleScroll);
      window.removeEventListener('resize', () => handleScroll);
    };
  }, [isMobile]);

  return (
    <NavHeaderContainer style={containerStyle} ref={headerRef} sticky={sticky || isSticky}>
      <NavHeaderItemsContainer>
        <NeuVybeLogoTypeWrapper>
          {isMobile ? (
            <Link onClick={handleNeuVybeLogoClick} to={routes.home}>
              <NeuVybeLogoMark src={NeuVybeLogoMarkImage} />
            </Link>
          ) : (
            <Link onClick={handleNeuVybeLogoClick} to={routes.home}>
              <NeuVybeLogoType src={NeuVybeLogoTypeImage} />
            </Link>
          )}
        </NeuVybeLogoTypeWrapper>
        <PrimaryButton onClick={handleContactButtonClick}>Contact</PrimaryButton>
        <ContactFormContainer
          fullScreen={isMobile}
          open={openDialog}
          onClose={handleContactFormClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ContactFormHeaderWrapper>
            <Box textAlign="center" mx="auto" mt={['100px', '50px']}>
              <LargeTitle>Contact Us</LargeTitle>
            </Box>
            <ContactFormCloseButton aria-label="close" onClick={handleContactFormClose}>
              <CloseIcon color="inherit" />
            </ContactFormCloseButton>
          </ContactFormHeaderWrapper>
          <form>
            <Box textAlign="center" width="100%" mx="auto" mt="25px">
              <Box>
                <ContactFormTextField
                  placeholder={'Your full name'}
                  variant="outlined"
                  margin="none"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
              </Box>
              <Box mt="25px">
                <ContactFormTextField
                  placeholder={'Your email address'}
                  variant="outlined"
                  margin="none"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </Box>
              <Box mt="25px">
                <ContactFormTextField
                  placeholder={'Your phone number (optional)'}
                  variant="outlined"
                  margin="none"
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={formik.handleChange}
                  value={formik.values.phoneNumber}
                />
              </Box>
              <Box mt="25px">
                <ContactFormTextField
                  placeholder={'Write a message...'}
                  variant="outlined"
                  margin="none"
                  multiline
                  rows="8"
                  id="message"
                  name="message"
                  onChange={formik.handleChange}
                  value={formik.values.message}
                />
              </Box>
            </Box>
            <Box textAlign="center" mx="auto" my="50px">
              <PrimaryButton disabled={formik.isSubmitting} onClick={formik.handleSubmit}>
                Submit
              </PrimaryButton>
            </Box>
          </form>
        </ContactFormContainer>
      </NavHeaderItemsContainer>
    </NavHeaderContainer>
  );
};

export default withSnackbar(NavHeader);
