import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ComboTextField, SignUpButton } from './styles';

interface InputComboProps {
  placeholder?: string;
  onClick?: () => void;
  onChange?: (e: KeyboardEvent) => void;
  value?: string;
  error?: boolean;
  success?: boolean;
}

const InputCombo = ({ error, placeholder, onClick, onChange, value, success }: InputComboProps) => {
  return (
    <ComboTextField
      value={value}
      placeholder={placeholder}
      margin="none"
      onChange={onChange}
      onKeyPress={(e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          onClick && !success && onClick();
        }
      }}
      error={error}
      endAdornment={
        <InputAdornment position="end">
          <SignUpButton size="medium" aria-label="sign up" disabled={success} onClick={onClick}>
            Sign up
          </SignUpButton>
        </InputAdornment>
      }
    />
  );
};

export default InputCombo;
