import React, { CSSProperties } from 'react';
import {
  PrimaryButtonStyled,
  Primary2ButtonStyled,
  SecondaryButtonStyled,
  Image,
  ImageAvatar,
  ImageButton,
  LinkTextButtonStyled
} from './styles';
import appStoreDownloadImage from '../../media/images/download-on-the-app-store-apple.svg';
import googlePlayStoreDownloadImage from '../../media/images/google-play-badge.svg';
import { IconButtonProps } from '@material-ui/core/IconButton';

export const PrimaryButton = (props: any) => {
  return <PrimaryButtonStyled {...props} />;
};

export const Primary2Button = (props: any) => {
  return <Primary2ButtonStyled {...props} />;
};

export const SecondaryButton = (props: any) => {
  return <SecondaryButtonStyled disableRipple {...props} />;
};

export const AppStoreDownloadButton = (props: IconButtonProps) => {
  return (
    <ImageButton {...props}>
      <Image src={appStoreDownloadImage} />
    </ImageButton>
  );
};

export const GooglePlayStoreDownloadButton = (props: IconButtonProps) => {
  return (
    <ImageButton {...props}>
      <Image src={googlePlayStoreDownloadImage} />
    </ImageButton>
  );
};

export interface ImageItemButtonProps extends IconButtonProps {
  src: string;
  imageStyle?: CSSProperties;
  playing?: boolean;
}

export const ImageItemButton = ({ src, imageStyle, playing, ...props }: ImageItemButtonProps) => {
  return (
    <ImageButton {...props}>
      <ImageAvatar style={imageStyle} src={src} />
    </ImageButton>
  );
};

export const LinkTextButton = (props: IconButtonProps) => {
  return <LinkTextButtonStyled {...props} />;
};
