import React, { useState, useCallback } from 'react';
import mixpanel from 'mixpanel-browser';
import Box from '@material-ui/core/Box';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { BottomHeroContainer, BottomHeroContainerOverlay, BottomHeroInfoContainer, BottomHeroInfo } from './styles';
import { Footnote2, LargeTitle, SubHeadline } from '../Typography';
import Countdown from '../Countdown';
import InputCombo from '../InputCombo';
import Footer from '../Footer';
import { validateEmail } from '../../shared/utils';
import Firebase from '../../classes/firebase';

const BottomHero = (props: WithSnackbarProps) => {
  const LAUNCH_DATE_UTC = new Date('10/10/2020').toUTCString();
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [hasEmailError, setEmailError] = useState(false);

  const sumbitEmail = useCallback(async () => {
    const isEmailValid = validateEmail(email);

    if (isEmailValid) {
      try {
        Firebase.saveComingSoonEmail(email);

        // reset form
        setEmail('');
        setEmailError(false);
        setEmailSubmitted(true);

        const message = "Thanks for signing up! We'll follow-up via email shortly with beta access Instructions.";
        props.enqueueSnackbar(message, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        });

        mixpanel.track('Person Signed Up', {
          timestamp: new Date().toISOString()
        });
      } catch (err) {
        console.error('ERROR > ', err);
        setEmailError(true);
        setEmailSubmitted(false);
      }
    } else {
      setEmailError(true);
    }
  }, [email]);

  const showErrorForEmailInput = email.length > 0 && hasEmailError;

  return (
    <BottomHeroContainer>
      <BottomHeroContainerOverlay>
        <BottomHeroInfoContainer>
          <BottomHeroInfo mx="auto" textAlign="center">
            <Box width="80%" mb="20px" mx="auto">
              <LargeTitle>Ready for the future of music?</LargeTitle>
            </Box>
            <Box mb="15px">
              <Footnote2 style={{ textTransform: 'uppercase' }}>24/7 live artificially intelligent DJs</Footnote2>
            </Box>
            <Box mb="50px">
              <Countdown date={LAUNCH_DATE_UTC} />
            </Box>
            <Box mb="25px">
              <SubHeadline>Sign Up for beta release!</SubHeadline>
            </Box>
            <Box>
              <InputCombo
                error={showErrorForEmailInput}
                success={emailSubmitted}
                value={email}
                placeholder="Your email address"
                onChange={(e: any) => setEmail(e.target.value)}
                onClick={sumbitEmail}
              />
            </Box>
          </BottomHeroInfo>
          <Footer />
        </BottomHeroInfoContainer>
      </BottomHeroContainerOverlay>
    </BottomHeroContainer>
  );
};

export default withSnackbar(BottomHero);
