import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import theme from '../../../src/theme';

interface NavHeaderContainerProps {
  sticky?: boolean;
}

export const NavHeaderContainer = styled.div<NavHeaderContainerProps>`
  z-index: 2;
  position: fixed;
  width: 100%;
  background: ${props => (props.sticky ? theme.colors.BACKGROUND_MAIN : 'inherit')};
  transition: background-color 0.2s linear;
  padding: 20px 0;

  @media (max-width: ${theme.mediaBreakPoints.mobileMax}px) {
    padding: 0;
  }
`;

export const NavHeaderItemsContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
`;

export const NeuVybeLogoType = styled.img`
  width: 100%;
  user-select: none;
  cursor: pointer;
`;

export const NeuVybeLogoMark = styled.img`
  width: 50%;
  margin-left: -18px;
  user-select: none;
  cursor: pointer;
`;

export const NeuVybeLogoTypeWrapper = styled.div`
  width: 100%;
  max-width: 285.65px;
`;

export const ContactFormContainer = styled(Dialog)`
  background: rgba(0, 0, 0, 0.5);
  width: 100%;

  .MuiDialog-paper {
    background: ${theme.colors.BACKGROUND_SECONDARY};
    min-width: 475px;
    padding: 50px;

    @media (max-width: ${theme.mediaBreakPoints.mobileMax}px) {
      padding: 0;
      min-width: 0;
    }
  }
`;

export const ContactFormCloseButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    width: 50px;
    color: black;
    background: gray;
    margin-top: 20px;
    margin-right: 20px;
    top: 0;
    right: 0;

    &:hover {
      background: gray;
      color: white;
    }
  }
`;

export const ContactFormHeaderWrapper = styled(Box)`
  &.MuiIconButton-root {
    width: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ContactFormTextField = styled(TextField)`
  width: 100%;
  min-width: 475px;
  background: transparent;

  .MuiOutlinedInput-root {
    border: 1px solid #938e9d;
    border-radius: 5px;
    color: white;

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }

  @media (max-width: ${theme.mediaBreakPoints.mobileMax}px) {
    width: 80%;
    min-width: 0;
  }

  input::placeholder,
  textarea::placeholder {
    color: #938e9d;
  }
`;
