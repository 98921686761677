import React from 'react';
import theme from '../theme';
import { createGlobalStyle } from 'styled-components';

export const fontsUrl = `https://fonts.googleapis.com/css?family=Abhaya+Libre:800|Montserrat:400,600,700&display=swap`;

export const GlobalStyles = createGlobalStyle`
  @import url(${fontsUrl});

  body {
    background: ${theme.colors.BACKGROUND_MAIN};
    margin: 0;
    padding: 0;
  }
`;

export const routes = {
  home: '/',
  privacyPolicy: '/privacy-policy',
  termsOfUse: '/terms-of-use'
};
