import styled from 'styled-components';
import theme from '../../theme';

export const FooterContainer = styled.div`
  width: 80%;
  margin: auto;
  padding: 150px 0 50px;
`;

export const NeuVybeLogoMark = styled.img`
  width: 96px;
  user-select: none;
  pointer-events: none;
`;

export const FooterTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${theme.mediaBreakPoints.tabletMax}px) {
    flex-direction: column;
  }
`;

export const FooterBottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: ${theme.mediaBreakPoints.tabletMax}px) {
    margin-top: 0;
  }
`;

export const FooterLogoCombo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${theme.mediaBreakPoints.tabletMax}px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

export const SocialMediaItemsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 50px;

  @media (max-width: ${theme.mediaBreakPoints.tabletMax}px) {
    margin-bottom: 70px;
  }
`;
